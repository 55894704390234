import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { AuthenticationGuard } from "./components/authentication-guard";
import { Route, Routes } from "react-router-dom";
import { OrganisationPage } from "./pages/organisations-page";
import { CallbackPage } from "./pages/callback-page";
import { DronesPage } from "./pages/drones-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { MapPage } from "./pages/map-page";
import { CircularProgress } from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { FlightsPage } from "./pages/flights-page";
import { RealtimeMap } from "./pages/realtime-map";

export const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route
        path="/maps"
        element={<AuthenticationGuard component={MapPage} />}
      />
      <Route
        path="/organisation"
        element={<AuthenticationGuard component={OrganisationPage} />}
      />
      <Route
        path="/drones"
        element={<AuthenticationGuard component={DronesPage} />}
      />
      <Route
        path="/flights"
        element={<AuthenticationGuard component={FlightsPage} />}
      />
      <Route
        path="/realtime-map"
        element={<AuthenticationGuard component={RealtimeMap} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
