import React, { useContext, useEffect, useState } from 'react';
import {Permission} from "./auth-types";
import usePermission from "./usePermission";
import PermissionContext from './auth-context';

type Props = {
    to: Permission;
    fallback?: JSX.Element | string;
    loadingComponent?: JSX.Element | string;
    children: React.ReactNode;
 
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const RequirePermission: React.FunctionComponent<Props> = ({to, fallback,loadingComponent, children}) => {
    
    // We "connect" to the provider thanks to the PermissionContext
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed] = useState<boolean>();
    const {isAllowedTo} = useContext(PermissionContext);

    useEffect(() => {
        isAllowedTo(to).then((allowed) => {
            setLoading(false);
            setAllowed(allowed);
        })
    });

    if(loading){
        return <>{loadingComponent}</>;
    }

    // If the user has that permission, render the children
    if(allowed){
        return <>{children}</>;
    }

    // Otherwise, render the fallback
    return <>{fallback}</>;
};

export default RequirePermission;