import React, { useEffect, useRef, useState, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import mqtt from "mqtt";
import { LoggedInPageLayout } from "src/components/page-layout";
import RequirePermission from "src/authorization/require-permission";
import { AUTHORIZATION_REALTIME_MAP_READ } from "src/authorization/auth-types";
import { Box, Container, Typography } from "@mui/material";
import droneIcon from "../images/drone-icon.png";
import airplaneIcon from "../images/airplane-icon.png";
import thrdDroneIcon from "../images/3rd-drone-icon.png";
import thrdHelicopterIcon from "../images/thrd-helicopter.svg";
import { useAuth0 } from "@auth0/auth0-react";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYXJuZWRvZGdlIiwiYSI6ImNsbmRhcnI1djAzYTEyanFwaXJpMzd1Y24ifQ.iBmdW1js0Oaq0s3wvdwDyA";

interface Position {
  lat: number;
  lng: number;
  alt: number;
  speed: number;
  uasType: string;
}

interface DroneData {
  id: string;
  aircraftType: string;
  speed: number;
  status: string;
  position: Position;
  path: Position[];
}

interface AirplaneData {
  id: string;
  latitude: number;
  longitude: number;
  altitude: number;
  call_sign: string;
  ground_speed: number;
  status: string;
  beacon_type: string;
  course: number; // Added course property to reflect flight direction
}


export const RealtimeMap: React.FC = () => {
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<Record<string, mapboxgl.Marker>>({});
  const popupsRef = useRef<Record<string, mapboxgl.Popup>>({}); // Track multiple popups
  const [drones, setDrones] = useState<Record<string, DroneData>>({});
  const [openPopups, setOpenPopups] = useState<Record<string, boolean>>({}); // Track open popups per drone

  const airplaneMarkersRef = useRef<Record<string, mapboxgl.Marker>>({});
  const [airplanes, setAirplanes] = useState<AirplaneData[]>([]);

  const { getAccessTokenSilently } = useAuth0();
  const accessToken = getAccessTokenSilently();

  const mapContainerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null && mapRef.current === null) {
      mapRef.current = new mapboxgl.Map({
        container: node,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [10.70553025100827, 59.91335277181008],
        zoom: 8,
      });

      mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-left");
      mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-left");
    }
  }, []);

  useEffect(() => {
    const connectToMqtt = async () => {
      const token = await getAccessTokenSilently();
      const client = mqtt.connect("wss://api.dev.airdodge.io/mqtt", {
        password: token,
        username: "fe_jwt_client",
      }); // Update if necessary

      client.on("connect", () => {
        client.subscribe("/rid/records/#");
      });

      client.on("message", (topic, message) => {
        const data = JSON.parse(message.toString());
        const droneId = data.uasId.registrationId;

        setDrones((prevDrones) => {
          const currentDrone = prevDrones[droneId] || {
            id: droneId,
            status: data.operationalStatus,
            aircraftType: data.aircraftType,
            speed: data.speed,
            position: data.position,
            path: [],
          };
          const updatedPath = [...currentDrone.path, data.position];

          return {
            ...prevDrones,
            [droneId]: {
              ...currentDrone,
              position: data.position,
              path: updatedPath,
            },
          };
        });
      });

      return () => {
        client.end();
      };
    };

    connectToMqtt();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!mapRef.current) return;

    Object.values(drones).forEach((drone) => {
      const { position, path } = drone;

      if (markersRef.current[drone.id]) {
        markersRef.current[drone.id].setLngLat([position.lng, position.lat]);

        // If the popup for this drone is open, update the popup's position
        if (openPopups[drone.id] && popupsRef.current[drone.id]) {
          popupsRef.current[drone.id]!.setLngLat([position.lng, position.lat]);
        }
      } else {
        const markerElement = document.createElement("div");
        markerElement.style.backgroundImage = `url(${droneIcon})`;
        markerElement.style.width = "25px";
        markerElement.style.height = "25px";
        markerElement.style.backgroundSize = "100%";

        const newMarker = new mapboxgl.Marker(markerElement)
          .setLngLat([position.lng, position.lat])
          .addTo(mapRef.current!);

        newMarker.getElement().addEventListener("click", () => {
          setOpenPopups((prev) => ({ ...prev, [drone.id]: true }));

          if (!popupsRef.current[drone.id]) {
            popupsRef.current[drone.id] = new mapboxgl.Popup({
              offset: 25,
              closeOnClick: false,
              closeButton: false,
            });
          }

          popupsRef.current[drone.id]
            .setLngLat([position.lng, position.lat])
            .setHTML(
              `
              <div style="z-index: 9999; position: relative;">
                <button id="custom-close-button-${drone.id}" style="position: absolute; top: -14px; right: -10px; color: black; border: none; cursor: pointer; padding: 0px 0px; width: 30px; height: 20px; border-radius: 0px; font-weight: bold;">x</button>
                <p style="padding: 8px 0 0 0; margin: 4px 0;"><strong>Registration ID:</strong> ${drone.id}</p>
                <p style="margin: 3px 0;"><strong>UAS Type:</strong> ${drone.aircraftType}</p>
                <p style="margin: 3px 0;"><strong>Status:</strong> ${drone.status}</p>
                <p style="margin: 3px 0;"><strong>Altitude:</strong> ${position.alt}m HAE</p>
                <p style="margin: 3px 0;"><strong>Speed:</strong> ${drone.speed} m/s</p>
              </div>
            `
            )
            .addTo(mapRef.current!);

          setTimeout(() => {
            const closeButton = document.getElementById(
              `custom-close-button-${drone.id}`
            );
            if (closeButton) {
              closeButton.onclick = () => {
                setOpenPopups((prev) => ({ ...prev, [drone.id]: false }));
                popupsRef.current[drone.id]?.remove();
              };
            }
          }, 0);
        });

        markersRef.current[drone.id] = newMarker;
      }

      const lineCoordinates = path.map((point) => [point.lng, point.lat]);

      if (mapRef.current!.getSource(drone.id)) {
        (mapRef.current!.getSource(drone.id) as mapboxgl.GeoJSONSource).setData(
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: lineCoordinates,
                },
              },
            ],
          }
        );
      } else {
        mapRef.current!.addSource(drone.id, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: lineCoordinates,
                },
              },
            ],
          },
        });

        mapRef.current!.addLayer({
          id: `path-${drone.id}`,
          type: "line",
          source: drone.id,
          layout: { "line-join": "round", "line-cap": "round" },
          paint: { "line-color": "#007cbf", "line-width": 2 },
        });
      }
    });
  }, [drones, openPopups]);

  useEffect(() => {
    const fetchAirplaneData = async () => {
      if (!mapRef.current) return;
      const bounds = mapRef.current.getBounds();
      const viewport = `${bounds.getSouthWest().lat},${bounds.getSouthWest().lng},${bounds.getNorthEast().lat},${bounds.getNorthEast().lng}`;

      try {
        const response = await fetch(
          `https://public-api.safesky.app/v1/beacons/?viewport=${viewport}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "e76873cdd7314adaaf60c2b13a6548e9",
            },
          }
        );

        if (response.ok) {
          const data: AirplaneData[] = await response.json();

          const updatedAirplanes = data.map((plane) => {
            let course = plane.course;
            return { ...plane, course };
          });

          setAirplanes(updatedAirplanes);
        } else {
          console.error("Failed to fetch airplane data");
        }
      } catch (error) {
        console.error("Error fetching airplane data:", error);
      }
    };

    // Initial fetch and interval setup
    fetchAirplaneData();
    const interval = setInterval(fetchAirplaneData, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;

    // Keep track of existing airplane IDs
    const currentIds = new Set(airplanes.map((plane) => plane.id));

    // Remove markers that are no longer present in the updated data
    Object.keys(airplaneMarkersRef.current).forEach((id) => {
      if (!currentIds.has(id) && !id.startsWith('text-')) {
        airplaneMarkersRef.current[id].remove();
        airplaneMarkersRef.current[`text-${id}`].remove();
        delete airplaneMarkersRef.current[id];
      }
    });

    airplanes.forEach((plane) => {
      const {
        id,
        latitude,
        longitude,
        altitude,
        call_sign,
        ground_speed,
        status,
        beacon_type,
        course,
      } = plane;

      if (airplaneMarkersRef.current[id]) {
        airplaneMarkersRef.current[id].setLngLat([longitude, latitude]);
        if (airplaneMarkersRef.current[`text-${id}`]) {
          airplaneMarkersRef.current[`text-${id}`].setLngLat([
            longitude,
            latitude,
          ]);
        }

        airplaneMarkersRef.current[id].getPopup().setHTML(
          `<div style="padding-top: 8px">` +
            `<strong>Call Sign:</strong> ${call_sign}<br>` +
            `<strong>Type:</strong> ${beacon_type}<br>` +
            `<strong>Status:</strong> ${status}<br>` +
            `<strong>Altitude:</strong> ${altitude}m AMSL<br>` +
            `<strong>Speed:</strong> ${ground_speed} km/h<br>` +
          `</div>`
        )
        airplaneMarkersRef.current[id].setRotation(course);

      } else {
        // Create icon marker with rotation
        const iconElement = document.createElement("div");
        if (beacon_type == "HELICOPTER") {
          iconElement.style.backgroundImage = `url(${thrdHelicopterIcon})`;
          iconElement.style.width = "35px";
          iconElement.style.height = "35px";
        } else if (beacon_type == "UAV") {
          iconElement.style.backgroundImage = `url(${thrdDroneIcon})`;
          iconElement.style.width = "25px";
          iconElement.style.height = "25px";
        } else {
          iconElement.style.backgroundImage = `url(${airplaneIcon})`;
          iconElement.style.width = "25px";
          iconElement.style.height = "25px";
        }

        iconElement.style.backgroundSize = "100%";

        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
          `<div style="padding-top: 8px">` +
            `<strong>Call Sign:</strong> ${call_sign}<br>` +
            `<strong>Type:</strong> ${beacon_type}<br>` +
            `<strong>Status:</strong> ${status}<br>` +
            `<strong>Altitude:</strong> ${altitude}m AMSL<br>` +
            `<strong>Speed:</strong> ${ground_speed} km/h<br>` +
          `</div>`
        );

        const aircraftIconMarker = new mapboxgl.Marker({
          element: iconElement,
          rotation: course, // Set rotation to reflect airplane direction
          rotationAlignment: "map", // Align rotation to the map's bearing
        })
          .setLngLat([longitude, latitude])
          .setPopup(popup)
          .addTo(mapRef.current!);

        // Save marker in the reference object
        airplaneMarkersRef.current[id] = aircraftIconMarker;

        // Create text marker without rotation
        const textElement = document.createElement("div");
        textElement.style.paddingTop = "83px";
        textElement.style.fontSize = "small"; // Set a smaller font size
        textElement.style.fontWeight = "400"; // Make the text bold
        textElement.style.fontFamily = "Arial, sans-serif"; // Set font to Arial
        textElement.style.color = "black";
        textElement.style.textAlign = "center";
        textElement.style.marginTop = "0px";
        textElement.style.lineHeight = "1";
        textElement.innerHTML = `${call_sign}<br>${altitude}m`;

        const textMarker = new mapboxgl.Marker({
          element: textElement,
        })
          .setLngLat([longitude, latitude - 0.0001]) // Adjust latitude slightly to place text below the icon
          .setPopup(popup)
          .addTo(mapRef.current!);

        // Save text marker in the reference object
        airplaneMarkersRef.current[`text-${id}`] = textMarker;
      }
    });
  }, [airplanes]);

  return (
    <LoggedInPageLayout>
      <RequirePermission
        to={AUTHORIZATION_REALTIME_MAP_READ}
        fallback={<Box>Unauthorized!</Box>}
      >
        <Container style={{ padding: "0" }}>
          <Box
            ref={mapContainerRef}
            style={{
              flex: 1,
              width: "calc(100vw - 250px)",
              height: "calc(100vh - 52px)",
            }}
          />
        </Container>
      </RequirePermission>
    </LoggedInPageLayout>
  );
};
