import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Button from "@mui/material/Button";

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/realtime-map",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <Button variant="contained" size="large" onClick={handleLogin}>
      Login
    </Button>
  );
};
